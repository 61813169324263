











































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {formatNumberToPercent} from '@/utils/formatNumberToPercent';
import { IPPP } from '@/models/Performance';
import PerformanceType from '@/models/Performance';
import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';
import { getEntityNameByEntityTypeId } from '@/utils/getEntityNameByEntityTypeId';
import { getIconByEntityTypeId } from '@/utils/getIconByEntityTypeId';

  interface IManager  {
        id : number;
        name : string;
        pppTickerId : number|null;
        benchmarkId : number|null;
        partPeriodPerformances : {
            performancePeriodTag : string;
            tickerValue : string;
            }[];
            parentEntityName : string;
    }
@Component
export default class ManagerList extends Vue {
  @Prop({type: Number, required: true}) managerInFocus!: number;

  @Prop({type: Array, required: true}) managerData!: Array<PerformanceType>;

  @Prop({type:Boolean, required:false, default:false}) showTotals: boolean;

  @Prop({type: Boolean, required: false}) showAltCalcData!: boolean;

  getEntityNameByEntityTypeId (entityTypeId: TEntityTypeDto): string {
      return getEntityNameByEntityTypeId(entityTypeId);
  }

  getIconByEntityTypeId (entityTypeId: TEntityTypeDto): string {
      return getIconByEntityTypeId(entityTypeId);
  }

  get featureFlagIsOnForMultiCurrency () : boolean {
      return process.env.VUE_APP_FEATURE_MULTI_CURRENCY_CHANGES_IS_LIVE === 'true';
  }

  get portfolioBaseCurrency (): string | null {
      return this.$store.state.selectedScheme.defaultCurrency || null;
  }

  get currencySymbol (): string {
      return this.$store.getters.getCurrencySymbol;
  }

  get isPerformanceView (): boolean {
      return this.$route.name === 'InvestmentPerformanceOverrides';
  }

  get isBenchmarkView (): boolean {
      return this.$route.name === 'BenchmarkPerformanceOverrides';
  }

  get isAlternativePerformanceSettingsView (): boolean {
      return this.$route.name === 'AlternativePerformanceSettings';
  }

  get managerListData (): IManager[] {
      // convert the managerData to a string and then back to an object to remove the circular reference
      const managers = this.managerData.map((manager) => ({
          id: manager.id,
          name: manager.name,
          pppTickerId: manager.pppTickerId,
          benchmarkId: manager.benchmarkId,
          parentEntityName: manager.parentEntityName,
          entityTypeId: manager.entityTypeId,
          partPeriodPerformances: manager.partPeriodPerformances?.map((ppp: IPPP) => {
              return {
                  performancePeriodTag: ppp.performancePeriodTag,
                  tickerValue: formatNumberToPercent(ppp.tickerValue / 100, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1})
              };

          })

      }));
      return managers;

  }

  cropName (name:string):string {
      const parts = name.split('&mdash;');
      const CROP_LENGTH = 30;
      const firstPart = parts[0].length > CROP_LENGTH ? parts[0].substring(0, CROP_LENGTH) + '&hellip;' : parts[0];
      return parts.length > 1 ? `${firstPart}&mdash;<b>${parts[1]}<b>` : firstPart;
  }

  calcPPPperformancePeriodTagWithValues (partPeriodPerformances: any[]): Array<{performancePeriodTag:string, tickerValue:string}> {
      if (this.isPerformanceView){
          return partPeriodPerformances.filter((ppp) => ppp.benchmarkId === null).map((ppp) => ({performancePeriodTag: ppp.performancePeriodTag, tickerValue:
            formatNumberToPercent(ppp.tickerValue / 100, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 })
          }));
      } else {
          return partPeriodPerformances.filter((ppp) => ppp.benchmarkId !== null).map((ppp) => ({performancePeriodTag: ppp.performancePeriodTag, tickerValue: ppp.tickerValue}));
      }
  }

  get buttonTitle (): string {
      return this.isPerformanceView ? 'Create Performance Part Period' : 'Create Benchmark Part Period';
  }

  get showPPPButton (): boolean {
      return this.isPerformanceView || this.isBenchmarkView;
  }

  openPPPModal (managerId: number, benchmarkId: number|null, pppTickerId: number|null ): void {
      this.$emit('open-ppp-modal', managerId, benchmarkId, pppTickerId);
  }
}
