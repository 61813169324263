






















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseModal from '@/components/BaseModal.vue';
import TickerDetails from '@/components/TickerDetails.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import VTooltip from 'v-tooltip';
import { IBenchmark } from '@/interfaces/entity/IClientEntity';
import {formatNumberToPercent} from '@/utils/formatNumberToPercent';
import {formatToDisplayDateYY} from '@/utils/formatToDisplayDate';
import  BaseButton  from '@/components/BaseElements/BaseButton.vue';
import BaseSwitch from '@/components/BaseElements/BaseSwitch.vue';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';


Vue.use(VTooltip);

@Component({
    components: {
        BaseModal,
        TickerDetails,
        LoadingOverlay,
        BaseButton,
        ConfirmDeletion,
        BaseSwitch
    },
})
export default class BenchmarkRules extends Vue {


    @Prop({ type: [Array, null], required: true }) benchmarkRules: Array<IBenchmark>| null

    @Prop({ type: Boolean, required: false, default: false }) isBenchmarkRuleAPIPending: boolean

    @Prop({ type: Boolean, required: false, default: false }) canUseDynamicOverrides: boolean

    MAX_BENCHMARKS_COUNT = 3;

    deleteModal = {
        active: false,
        deleteKeyword: '',
        deleteById: -1
    };

    discardDeleteModal (): void {
        this.resetDeleteModal();
    }

    confirmDelete (): void {
        this.deleteBenchmarkById(this.deleteModal.deleteById);
    }

    showConfirmDeleteModal (benchmarkId: number | null, benchmarkName: string): void {
        this.deleteModal.active = true;
        this.deleteModal.deleteById = benchmarkId;
        this.deleteModal.deleteKeyword = benchmarkName;
    }

    get benchmarkCount (): number {
        return this.benchmarkRules.length;
    }

    get showAddBenchmarkButton (): boolean {
        return this.benchmarkCount < this.MAX_BENCHMARKS_COUNT;
    }

    resetDeleteModal (): void {
        this.deleteModal.active = false;
        this.deleteModal.deleteById = -1;
        this.deleteModal.deleteKeyword = '';
    }

    openBenchmarkById (benchmarkId: number | null): void {
        this.$emit('open-benchmark-by-id', benchmarkId);
        this.resetDeleteModal();
    }

    deleteBenchmarkById (benchmarkId: number | null): void {
        this.$emit('delete-benchmark-by-id', benchmarkId);
        this.resetDeleteModal();
    }

    makePrimaryById (benchmarkId: number | null): void {
        this.$emit('make-primary-by-id', benchmarkId);
    }

    toggleDynamicOverridesById (benchmarkId: number | null): void {
        this.$emit('toggle-dynamic-overrides-by-id', benchmarkId);
    }

    numberValueFormatter (value:number):string {
        if (value === null || Number.isNaN(value)) {
            return '';
        }
        return formatNumberToPercent(value, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }

    formatToDisplayDate (value: string): string {
        return formatToDisplayDateYY(new Date(value));
    }


}
